import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import SelectOutline from '../../common/dropdowns/SelectOutline';
import InputBase from '../../common/inputs/InputBase';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import ArrowButton from '../../common/buttons/ArrowButton';
import { Script } from 'gatsby';
import dateIcon from '../../assets/images/dateIcon.svg';
// import InputNumber from '../../common/inputs/InputNumber';
import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';

import {
  getBrandByName,
  getLocationByBrandId,
  listBrands,
  listLocationEmail,
  listMembershipId,
} from '@/api/mutation';
import { RegisterContext } from '@/context/RegisterContext';
import { Auth, Storage } from 'aws-amplify';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import useFetch from '@/hooks/useFetch';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import { uppercaseFirstLetter } from '@/helpers/string.helper';
import { GoogleMap, Marker } from '@react-google-maps/api';
import markerImage from '@/assets/images/location-marker.png';
import CheckboxActive from '@/common/buttons/CheckboxActive';
const shortSearch = {
  mt: 'mount',
};
interface SlideProps {
  info: any;
  setInfo: (v) => void;
  onNext: () => void;
  onPrev: () => void;
  onUnderAge: () => void;
  isUnderAge: boolean;
  setContact: (v) => void;
  contact: any;
  setIsUnderAge: (v) => void;
  setStrapiData: (v: any) => void;
}

const Details: React.FC<SlideProps> = ({
  onNext,
  // onPrev,
  setInfo,
  info,
  onUnderAge,
  isUnderAge,
  setContact,
  contact,
  setIsUnderAge,
  setStrapiData,
}) => {
  const yourClub = useContext(RegisterContext).yourClub;
  const setYourClub = useContext(RegisterContext).setYourClub;

  // const [contact, setContact] = useState({
  //   firstName: '',
  //   middleName: '',
  //   lastName: '',
  //   email: '',
  //   phone: '',
  //   gender: '',
  //   dob: '',
  //   postCode: '',
  // });
  const [checking, setChecking] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [consent, setConsent] = useState(false);

  const [prefixNumber, setPrefixNumber] = React.useState<'+61' | '+64'>('+61');
  const [currentMaintenance, setCurrentMaintenance] = useState({});

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (e.target.name === 'postCode' && e.target.value.length > 4) {
      value = e.target.value.slice(0, 4);
    }
    if (
      e.target.name === 'phone' ||
      e.target.name === 'emergencyContactNumber'
    ) {
      value = prefixNumber + value;
      if (value.charAt(3) == '0') value = value.slice(0, 3) + value.slice(4);
    }
    const strapiPayload = {
      givenName: contact.firstName,
      middleName: contact.middleName,
      surname: contact.lastName,
    };
    // if (e?.target?.name === 'phone')
    setStrapiData({
      ...contact,
      [e.target.name === 'phone'
        ? 'mobileNumber'
        : e.target.name === 'firstName'
        ? 'givenName'
        : e.target.name === 'lastName'
        ? 'surname'
        : e.target.name]: value,
    });
    // else setStrapiData({ ...contact, [e.target.name]: value });

    setContact({ ...contact, [e.target.name]: value });
  };

  const validation = () => {
    if (moment(new Date()).diff(moment(currentAge[0]), 'years') < 18) {
      if (
        !(
          contact.emergencyContactName &&
          contact.emergencyContactName != '' &&
          contact.emergencyContactRelation &&
          contact.emergencyContactRelation != '' &&
          contact.emergencyContactNumber &&
          contact.emergencyContactNumber != '' &&
          contact.emergencyContactEmail &&
          contact.emergencyContactEmail != ''
        )
      ) {
        throw Error('please fulfill all the emergency contact info');
      } else if (contact.email === contact.emergencyContactEmail) {
        throw Error('You have entered a duplicate email!');
      } else if (!consent) {
        throw Error(
          'Please confirm you have permission from your parent/guardian.'
        );
      } else {
        if (
          !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(contact.emergencyContactEmail)
        ) {
          throw Error('You have entered an invalid emergency email address!');
        }
        if (
          !isValidPhoneNumber('+' + contact.emergencyContactNumber)

          // !/^\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/.test(
          //   '0' + contact.emergencyContactNumber.split(prefixNumber)[1]
          // )
        ) {
          throw Error('You have entered an invalid emergency phone number!');
        }
      }
    }
    const dataChecking = { ...contact };
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(dataChecking.email)) {
      throw Error('You have entered an invalid email address!');
    }
    if (
      !isValidPhoneNumber('+' + dataChecking.phone)
      // !/^\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/.test(
      //   '0' + dataChecking.phone.split('+61')[1]
      // )
    ) {
      throw Error('You have entered an invalid phone number!');
    }
  };

  const handleSubmit = async (currentAge) => {
    try {
      await Auth.signIn(contact.email.toUpperCase());
      toastError('This email already exist');
      return;
    } catch (e) {}
    try {
      validation();
      const dataChecking = { ...contact, dob: currentAge[0] };
      delete dataChecking.middleName;
      if (Object.values(dataChecking).every((x) => x !== '')) {
        setInfo({
          ...info,
          memberDetails: {
            ...info.memberDetails,
            address: '',
            postCode: contact.postCode,
            dob: moment(new Date(currentAge[0])).format('YYYY-MM-DD'),
            email: contact.email,
            givenName: contact.firstName,
            middleName: contact.middleName,
            surname: contact.lastName,
            mobileNumber: '+' + contact.phone,
            emergencyContactEmail: contact.emergencyContactEmail
              ? contact.emergencyContactEmail
              : info?.emergencyContactEmail,
            emergencyContactNumber: contact.emergencyContactNumber
              ? '+' + contact.emergencyContactNumber
              : info?.emergencyContactNumber,
            emergencyContactRelation: contact.emergencyContactRelation
              ? contact.emergencyContactRelation
              : info?.emergencyContactRelation,
            emergencyContactName: contact.emergencyContactName
              ? contact.emergencyContactName
              : info?.emergencyContactName,

            // brandId: location?.brandId,
          },
        });
        setStrapiData({
          dob: moment(new Date(currentAge[0])).format('DD/MM/YYYY'),
        });
        moment(new Date()).diff(moment(currentAge[0]), 'years') >= 8 &&
          onNext();
      } else setChecking(true);
    } catch (e) {
      toastError(e.message);
    }
  };
  const [graphLocations, setGraphLocations] = useState([]);

  const [getBrandId] = useLazyQuery(getBrandByName);
  const [getLocationByBrand] = useLazyQuery(getLocationByBrandId);

  const { data: dataPromotionBanner } = useFetch({
    params: 'global',
    populate: 'promotion, promotion.image',
  });

  const isShowPromotionBanner = React.useMemo(() => {
    return moment(moment().format('YYYY-MM-DD')).isBetween(
      dataPromotionBanner?.data?.attributes?.promotion?.startDate,
      dataPromotionBanner?.data?.attributes?.promotion?.endDate,
      undefined,
      '[]'
    );
  }, [dataPromotionBanner]);

  useEffect(() => {
    getBrandId().then((res) => {
      const brandId = res.data?.getBrandByName.items[0]?.id;
      getLocationByBrand({
        variables: {
          brandId,
          filter: { status: { eq: 'ACTIVE' } },
          limit: 1000,
        },
      }).then(async (location) => {
        const data = await Promise.all(
          location.data?.getLocationByBrandId.items.map(async (i) => {
            const img =
              (await Storage.get(i.clubImage)) ??
              'https://media.tacdn.com/media/attractions-splice-spp-674x446/09/c3/33/97.jpg';
            return { ...i, clubImage: img };
          })
        );
        setGraphLocations(data);
        // setLocation(data.find((item) => item?.suburb === 'TUGGERANONG'));
      });
    });
  }, []);
  useEffect(() => {
    if (
      info.memberDetails?.suburb &&
      contact?.dob &&
      !moment(new Date()).diff(moment(contact?.dob), 'years') < 8
    ) {
      if (document.getElementById('searchLocation'))
        document.getElementById('searchLocation').value =
          uppercaseFirstLetter(info.memberDetails?.suburb) +
          ', ' +
          info.memberDetails?.state;
    }
  }, [info]);

  const [currentStud, setCurrentStud] = useState();
  const [showLocation, setShowLocation] = useState([]);
  const inputThing = (value: string) => {
    if (value) {
      const temp = [];
      for (let i = 0; i < graphLocations.length; i++) {
        if (
          graphLocations[i]?.address1
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          graphLocations[i]?.name
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          graphLocations[i]?.name
            ?.toLowerCase()
            .includes(shortSearch[value]?.toLowerCase())
        ) {
          temp.push(graphLocations[i]);
        }
      }
      return setShowLocation(temp);
    } else {
      setInfo({
        ...info,
        memberDetails: {
          ...info.memberDetails,
          state: location?.state ?? '',
          suburb: location?.suburb ?? '',
        },
      });
    }
    return setShowLocation([]);
  };
  const setLocation = (location) => {
    if (
      location?.country?.toUpperCase() == 'AUSTRALIA' ||
      location?.country == ''
    )
      setPrefixNumber('+61');
    else setPrefixNumber('+64');
    const map = document.getElementById('currentStudioMap');
    // map.src = `https://maps.google.com/maps?q=${currentStud?.geoLocation.latitude},${currentStud?.geoLocation.longitude}&hl=es&z=14&amp;output=embed`;
    setInfo({
      ...info,
      membershipId: '',
      membershipLocationId: '',
      memberDetails: {
        ...info.memberDetails,
        country: location?.country,
        homeLocationId: location?.id,
        joinLocationId: location?.id,
        address: location?.address1,
        brandId: location?.brandId,
        // postCode: parseInt(location?.address?.postCode),
        state: location?.state,
        suburb: location?.suburb,
      },
    });
    setCurrentStud(location);
    setYourClub(location);
    setGeolocation({
      lat: location?.geoLocation?.latitude,
      lng: location?.geoLocation?.longitude,
    });

    setShowLocation([]);
  };

  React.useEffect(() => {
    if (moment(new Date()).diff(moment(contact?.dob), 'years') < 8) {
      onUnderAge();
    }
  }, [contact?.dob]);

  React.useEffect(() => {
    if (isUnderAge == false) {
      setContact({ ...contact, dob: '' });
    }
  }, [isUnderAge]);
  useEffect(() => {
    const parseQueryString = () => {
      let str = window.location.search;
      let objURL = {};

      str.replace(
        new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
        function ($0, $1, $2, $3) {
          objURL[$1] = $3;
        }
      );
      return objURL;
    };
    const qr = parseQueryString();
    if (Object.keys(qr).length > 0 && qr.gym) {
      const location = graphLocations.filter((item) => {
        return (
          item.name.toLowerCase() === decodeURIComponent(qr.gym).toLowerCase()
        );
      });
      if (location.length) handleSelectLocation(location[0]);
    }
  }, [graphLocations]);
  const mapRef = React.useRef<any>();
  const options = useMemo(
    () => ({
      disableDefaultUI: true,
      clickableIcons: true,
      zoomControl: true,
      mapId: '3d7f8a4902336c6d',
      styles: '',
    }),
    []
  );
  const onload = useCallback((map: any) => (mapRef.current = map), []);
  const [Geolocation, setGeolocation] = useState({
    lat: -35.417400886,
    lng: 149.064989477,
  });
  const mapSrc = `https://maps.google.com/maps/embed/v1/place?key=${process.env.GOOGLE_API_KEY}&q==10.8426686,106.7530519,9z`;
  const [showSelectClubError, setshowSelectClubError] = useState(false);
  const [currentAge, setCurrentAge] = useState();
  const locationHours = currentStud?.locationAccessTimes.items;
  const getTableData = (type: string) => {
    const typeData = locationHours.filter((i) => i.type === type);
    const mondayAccessTimes = typeData
      .filter((i) => i.day === 'MONDAY')
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const tuesdayAccessTimes = typeData
      .filter((i) => i.day === 'TUESDAY')
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const wednesdayAccessTimes = typeData
      .filter((i) => i.day === 'WEDNESDAY')
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const thursdayAccessTimes = typeData
      .filter((i) => i.day === 'THURSDAY')
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const fridayAccessTimes = typeData
      .filter((i) => i.day === 'FRIDAY')
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const saturdayAccessTimes = typeData
      .filter((i) => i.day === 'SATURDAY')
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const sundayAccessTimes = typeData
      .filter((i) => i.day === 'SUNDAY')
      .slice()
      .sort((a, b) => {
        const result = a.openTime > b.openTime ? 1 : -1;
        return result;
      });
    const tableData = [
      { id: 'monday', day: 'MONDAY', timePeriod: mondayAccessTimes },
      { id: 'tuesday', day: 'TUESDAY', timePeriod: tuesdayAccessTimes },
      { id: 'wednesday', day: 'WEDNESDAY', timePeriod: wednesdayAccessTimes },
      { id: 'thursday', day: 'THURSDAY', timePeriod: thursdayAccessTimes },
      { id: 'friday', day: 'FRIDAY', timePeriod: fridayAccessTimes },
      { id: 'saturday', day: 'SATURDAY', timePeriod: saturdayAccessTimes },
      { id: 'sunday', day: 'SUNDAY', timePeriod: sundayAccessTimes },
    ];
    return tableData;
  };
  const handleSelectLocation = (location) => {
    const locationName =
      uppercaseFirstLetter(location?.name) + ', ' + location?.state;
    document.getElementById('searchLocation').value = locationName;
    setLocation(location);
    setStrapiData({
      joinLocationId: locationName,
      homeLocationId: locationName,
      membershipLocationId: locationName,
      state: location?.address?.state,
      country: location?.country,
      homeClub: locationName,
    });
    setshowSelectClubError(false);
    setCurrentMaintenance(
      location?.locationMaintenance?.items?.filter(
        (item) =>
          moment().isBetween(
            item.startDateTime,
            item.endDateTime,
            null,
            '[]'
          ) && !item.isDeleted
      )?.[0]
    );
  };
  return (
    <div
      id="scroll-hidden"
      className="md:mx-0 sm:mt-8 mt-[44px] pb-[60px] items-start register flex  justify-between h-full overflow-auto md:pb-unset md:pb-0 hiddenScrollWidth overscroll-y-cover"
    >
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`}
        onLoad={() => setLoaded(true)}
      />
      {moment(new Date()).diff(moment(contact?.dob), 'years') < 8 ? (
        <div className="text-white text-start pb-[120px]">
          <p
            className="text-[40px] leading-[116.5%] mb-2 font-bold font-bicyclette sm:hidden block"
            onClick={() => {
              const iframe = document.getElementsByTagName('iframe');
              iframe[0].src = mapSrc;
            }}
          >
            WOW, YOU ARE STARTING YOUR
            <br /> FITNESS JOURNEY AT THE AGE OF{' '}
            {moment(new Date()).diff(moment(contact?.dob), 'years')}!
          </p>
          {contact?.firstName && (
            <h2 className="hidden sm:block text-primary font-bicyclette text-[25px] font-bold leading-[90%] uppercase border-b border-b-primary py-2 mb-4">
              welcome, {contact?.firstName}!
            </h2>
          )}
          <p className="text-lg mb-10 sm:font-bold sm:text-base sm:text-white sm:leading-[133.5%] sm:mb-5">
            To get you started and finish the sign-up process, you are required
            to come to Plus Fitness club with your parent or guardian during
            staffed hours.
          </p>
          <p className="text-[#7681FF] text-[25px] font-bold uppercase mb-3 font-bicyclette sm:text-white sm:text-base sm:font-bold sm:leading-[160%] sm:uppercase sm:mb-5 sm:border-b sm:border-white sm:border-opacity-40 sm:py-2">
            visit us in {currentStud?.name}
          </p>
          <div className="hidden sm:flex flex-col justify-between">
            <div className="text-base text-white">
              ADDRESS
              <span className="block text-base text-textSecondary">
                {currentStud?.address1}
              </span>
              {/* <span className="block text-base text-textSecondary">
                ACT 2900
              </span> */}
              <a
                href={'tel:' + currentStud?.phoneNumber}
                className="block text-base text-textSecondary"
              >
                P: {currentStud?.phoneNumber}
              </a>
              <a
                href={'mailto:' + currentStud?.email}
                className="block text-base text-textSecondary"
              >
                E: {currentStud?.email}
              </a>
            </div>
            <div className="text-base text-white mt-5">
              STAFFED HOURS
              {/* <span className="block text-base text-textSecondary">
                Monday to Thursday
              </span> */}
              {currentStud &&
                currentStud.locationAccessTimes.items &&
                getTableData('STAFF').map((i) => (
                  <div className=" text-base text-textSecondary flex py-2">
                    <span className="w-1/3">{i.day}: </span>
                    <span className="w-1/3">
                      {i.timePeriod.map((i) => (
                        <>
                          {i.openTime}-{i.closeTime}
                          <br />
                        </>
                      ))}
                    </span>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex gap-[32px] md:flex-col flex-row sm:mt-5">
            {/* <GymMap
                mapData={[{}]}
                refElm={mapRef}
                mapCenter={[
                  10.8426686,80.7530519
                ]}
                mapZoom={[20]}
              /> */}
            <div className="md:w-full w-[281px] h-[155px]">
              <GoogleMap
                zoom={14}
                center={{ lat: 1 * Geolocation.lat, lng: 1 * Geolocation.lng }}
                // options={options}
                onLoad={onload}
                mapContainerClassName="mapContainer"
              >
                <Marker
                  // onClick={() => {}}
                  position={{
                    lat: 1 * Geolocation.lat,
                    lng: 1 * Geolocation.lng,
                  }}
                  icon={markerImage}
                />
              </GoogleMap>
            </div>

            {/* {
              <iframe
                id="currentStudioMap"
                //  src={}
                src={mapSrc}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3251.551078221893!2d149.06647209807014!3d-35.41637645236136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b17ca813e1c7ca3%3A0xbfe8353674013c2f!2sSouth.Point%20Tuggeranong!5e0!3m2!1svi!2s!4v1686803026301!5m2!1svi!2s"
                className="md:w-full w-[281px] h-[155px]"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            } */}
            <div className="sm:hidden flex flex-col justify-between">
              <div className="text-[16px]">
                ADDRESS
                <br />
                {currentStud?.address1}
                <br />
                <a href={'tel:' + currentStud?.phoneNumber}>
                  P: {currentStud?.phoneNumber}
                </a>
                <br />
                E:{' '}
                <a href={'mailto:' + currentStud?.email} className="lowercase">
                  {currentStud?.email}
                </a>
              </div>
              <div className="text-[16px] md:mt-4 mt-2">
                STAFFED HOURS
                {/* <br />
                Monday to Thursday - 10:00am-2:00pm & 3.00pm-7.00pm
                <br />
                Friday - 10:00am-2:00pm
                <br />
                Saturday - 9:00am-1:00pm */}
                {currentStud &&
                  currentStud.locationAccessTimes.items &&
                  getTableData('STAFF').map((i) => (
                    <div className=" text-base flex py-2">
                      <span className="w-2/3">{i.day}: </span>
                      <span className="w-1/3">
                        {i.timePeriod.map((i) => (
                          <>
                            {i.openTime}-{i.closeTime}
                            <br />
                          </>
                        ))}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="flex gap-[19px] mt-6 mb-[47px] sm:w-full">
            {/* <PrimaryOutlinedButton text="GET DIRECTION" noArr /> */}
            <PrimaryOutlinedButton
              className="rounded-md !py-[7px] !min-h-[37px] sm:!w-full !w-[131px] !px-0 !text-[11px] "
              onClick={() => {
                const link = document.createElement('a');
                link.href = `https://www.google.com/maps?saddr=My+Location&daddr=${currentStud.geoLocation.latitude},${currentStud.geoLocation.longitude}&q=food&amp;z=14`;
                link.target = '_blank';
                link.click();
              }}
            >
              GET DIRECTION
            </PrimaryOutlinedButton>
            {/* <PrimaryOutlinedButton text="LARGER MAP" noArr /> */}
            <PrimaryOutlinedButton
              className="rounded-md !py-[7px] !min-h-[37px] sm:!w-full !w-[131px] !px-0 !text-[11px] "
              onClick={() => {
                const a = document.createElement('a');
                a.href = `https://www.google.com/maps?q=${currentStud.geoLocation.latitude},${currentStud.geoLocation.longitude}&q=food&amp;z=14`;
                a.target = '_blank';
                a.click();
              }}
            >
              LARGER MAP
            </PrimaryOutlinedButton>
          </div>
          <div className="w-[157px] sm:hidden">
            <ArrowButton
              text="back"
              onclick={() => {
                setIsUnderAge(false);
                setContact({ ...contact, dob: '' });
                setCurrentStud();
                setYourClub();
                setInfo({
                  ...info,
                  memberDetails: {
                    ...info.memberDetails,
                    state: '',
                    suburb: location?.suburb ?? '',
                  },
                });
              }}
            />
          </div>
        </div>
      ) : (
        <div className="w-full">
          <h1 className="text-primary text-left  font-bold text-[25px] leading-[90%] uppercase border-b border-b-brand-clime pb-2 w-full font-bicyclette">
            Select your home club
          </h1>

          <div className="w-full  justify-between">
            <div>
              <div className="mt-4 text-white lg:w-auto w-full relative">
                <div>
                  <div className="flex border-b border-borderLine min-w-full items-center relative justify-between">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 25 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {' '}
                      <line
                        x1="15.8912"
                        y1="14.8002"
                        x2="24.1415"
                        y2="22.2113"
                        stroke={'#2757FF'}
                        strokeWidth="1.57426"
                      />
                      <circle
                        cx="10.0022"
                        cy="10.3639"
                        r="7.15275"
                        transform="rotate(-72.0294 10.0022 10.3639)"
                        stroke={'#2757FF'}
                        strokeWidth="1.57426"
                      />
                    </svg>
                    <input
                      autoComplete="off"
                      type="text"
                      id="searchLocation"
                      className="bg-transparent  py-[15px] ml-[15px] gotham font-normal w-full text-sm sm:text-base leading-[160%] text-white outline-none"
                      onChange={(e) => inputThing(e.target.value)}
                    />
                    {info.memberDetails?.suburb && (
                      <span
                        className="absolute bottom-3 cursor-pointer right-0 text-white graphik-regular text-[18px] font-thin"
                        onClick={() => {
                          setInfo({
                            ...info,
                            memberDetails: {
                              ...info.memberDetails,
                              state: '',
                              suburb: location?.suburb ?? '',
                            },
                          });
                          document.getElementById('searchLocation').value = '';
                          setShowLocation([]);
                          setCurrentStud();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                          fill="none"
                        >
                          <path
                            d="M16.7559 16.4368L5.66841 5.34938"
                            stroke="white"
                            strokeWidth="1.11532"
                          />
                          <path
                            d="M5.54199 16.5595L16.5598 5.5417"
                            stroke="white"
                            strokeWidth="1.11532"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                  {showLocation.length > 0 && (
                    <div
                      className={`absolute top-[calc(53.4px+16px)] w-full border bg-black border-primary rounded-md sm:rounded-none z-20 max-h-[50vh] overflow-auto `}
                      id="scrollVert"
                    >
                      {showLocation.length > 0 &&
                        showLocation.map((location) => (
                          <div
                            className={`grid cursor-pointer hover:bg-[#2757FF] ${
                              location.clubImage ? '' : ''
                            }`}
                            onClick={() => {
                              handleSelectLocation(location);
                            }}
                          >
                            <p className="text-start px-[14px] pt-3 pb-[16px] hover:bg-primary bg-black border-primary">
                              {uppercaseFirstLetter(location?.name)},{' '}
                              {location?.state}
                            </p>

                            {/* {location.clubImage && (
                              <div>
                                <img src={location?.clubImage} />
                              </div>
                            )}
                            <div className="col-span-2 py-2 text-start justify-between flex flex-col">
                              <p className="gotham w-full text-[#7681FF]">
                                {location?.name}
                              </p>
                              <p className="graphik-regular w-full ">
                                {location?.address?.address}
                              </p>
                            </div>
                            <div className="py-2 flex flex-col justify-between justify-self-end text-[#7681FF]">
                              <p className="graphik-regular text-end">
                                {location?.phoneNumber}
                              </p>
                              <p className="text-white text-end gotham text-sm ">
                                From ${membership[0].costPrice}/week
                              </p>
                            </div> */}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
                {!currentStud && showSelectClubError && (
                  <div className="text-start mt-2 text-textError">
                    Please select your home club{' '}
                  </div>
                )}
                {Object.keys(currentMaintenance || {}).length > 0 && (
                  <div className="text-start mt-2 text-white text-[13px]">
                    {`  This location is currently undergoing maintenance from ${moment(
                      currentMaintenance.startDateTime
                    ).format('DD/MM/yyyy')}  to  ${moment(
                      currentMaintenance.endDateTime
                    ).format(
                      'DD/MM/yyyy'
                    )}. You can join now, but the club will not open until ${moment(
                      currentMaintenance.endDateTime
                    )
                      .add(1, 'days')
                      .format('MMMM D, YYYY')}`}
                  </div>
                )}
              </div>
              {/*<div className="text-white text-start flex flex-col mt-[21px] gap-2 uppercase text-sm font-medium">*/}
              {/*  Do you have a promo code?*/}
              {/*  <input*/}
              {/*    className="text-primary font-semibold rounded-md px-[6px] py-[9px] w-[147px]"*/}
              {/*    defaultValue="PF-"*/}
              {/*  />*/}
              {/*</div>*/}
              {/* <div className="mt-6">
                            <InputBase nameInput=""
                                    holder="Promo Code*"
                                    style="lg:w-full"
                                    isRequired={true}/>
                        </div> */}
              {/* Contact Detail */}
              <div className="mt-[43px] sm:mt-8">
                <h2 className="text-primary text-left font-bold text-[25px] uppercase leading-[90%] border-b border-b-brand-clime pb-2 font-bicyclette">
                  contact details
                </h2>
              </div>
              <div className="mt-[34px] sm:mt-5">
                <div className="grid md:grid-cols-1 grid-cols-2 gap-y-[21px] text-white gap-x-[32px]">
                  <InputBase
                    isShowRequired={checking && contact.firstName === ''}
                    onchange={handleChangeInput}
                    nameInput="firstName"
                    holder="First name*"
                    style="lg:w-full"
                    isRequired={true}
                    value={contact.firstName}
                    revertColor
                  />
                  <InputBase
                    isShowRequired={checking && contact.lastName === ''}
                    onchange={handleChangeInput}
                    nameInput="lastName"
                    holder="Last name*"
                    style="lg:w-full"
                    isRequired={true}
                    value={contact.lastName}
                    revertColor
                  />
                  <label
                    htmlFor="phoneNumber"
                    className={`relative flex justify-end py-[7px] md:h-[51px] flex-col border-b  ${
                      contact.phone && contact.phone !== ''
                        ? 'border-white'
                        : 'border-textSecondary'
                    }`}
                  >
                    {/* <p
                      className={`absolute top-[-1px] left-0 after:ml-0.5 after:text-white block text-[12px] gotham font-medium text-white `}
                    >
                      Phone number*
                    </p> */}
                    <span
                      htmlFor="phoneNumber"
                      className={`absolute z-10 bg-black block text-[12px] font-medium transition-all duration-300 sm:text-base sm:font-normal ${
                        !(contact.phone === '')
                          ? 'top-0 sm:-top-1 left-0 text-Secondary'
                          : 'top-[24px] sm:top-5 left-10 text-white'
                      } `}
                    >
                      Phone number*
                    </span>
                    <PhoneInput
                      placeholder=""
                      enableSearch
                      disableSearchIcon
                      htmlid="phoneNumber"
                      country="au"
                      value={contact?.phone || ''}
                      // onChange={(phone) => {
                      //   if (phone) {
                      //     setContact({ ...contact, phone: phone });
                      //     setStrapiData({
                      //       ...contact,
                      //       mobileNumber: phone,
                      //     });
                      //   } else {
                      //     setContact({ ...contact, phone: '' });
                      //   }
                      // }}
                      onChange={(phone, country) => {
                        if (phone.startsWith(country?.dialCode + '0')) {
                          phone =
                            country?.dialCode +
                            phone.slice(country?.dialCode.length + 1);
                          if (phone) {
                            setContact({ ...contact, phone: phone });
                            setStrapiData({
                              ...contact,
                              mobileNumber: phone,
                            });
                          } else {
                            setContact({ ...contact, phone: '' });
                          }
                        } else {
                          if (phone) {
                            setContact({ ...contact, phone: phone });
                            setStrapiData({
                              ...contact,
                              mobileNumber: phone,
                            });
                          } else {
                            setContact({ ...contact, phone: '' });
                          }
                        }
                      }}
                    />
                  </label>

                  <div className="col-span-1 md:col-span-1">
                    <InputBase
                      isShowRequired={checking && contact.email === ''}
                      onchange={handleChangeInput}
                      nameInput="email"
                      holder="Email*"
                      style="lg:w-full "
                      isRequired={true}
                      value={contact.email}
                      revertColor
                    />
                  </div>

                  {/* <InputNumber
                    prefix={prefixNumber}
                    onChangePrefix={(value) => setPrefixNumber(value)}
                    isShowRequired={checking && contact.phone === ''}
                    onchange={handleChangeInput}
                    nameInput="phone"
                    holder="Phone number*"
                    style="lg:w-full"
                    isRequired={true}
                    value={contact.phone}
                    revertColor
                  /> */}
                  <label
                  // onClick={() => {
                  //   setshowSelectClubError(true);
                  // }}
                  >
                    <div
                      className={`w-full border-b flex h-[47px]  flex-col pb-[2px] cursor-pointer  relative ${
                        currentAge && currentAge !== ''
                          ? 'border-b-[#d3d3d3]'
                          : 'border-b-borderLine'
                      } `}
                    >
                      <div className="absolute flex  bottom-0 w-full pb-[7px]">
                        {
                          <Flatpickr
                            onChange={(dateObj: any, dateStr: string) => {
                              setCurrentAge(dateObj);
                              setStrapiData({ dob: dateStr });
                            }}
                            value={currentAge ? new Date(currentAge) : ''}
                            options={{
                              dateFormat: 'd/m/Y',
                              enableTime: false,
                              time_24hr: false,
                              allowInput: false,
                              disableMobile: 'true',
                              maxDate: 'today',
                            }}
                          />
                        }
                      </div>
                      <label
                        className={`gotham text-xs sm:text-base absolute left-0 ${
                          currentAge && currentAge !== ''
                            ? 'text-white top-[-5px]'
                            : 'text-white top-[23px] sm:bottom-[28px]'
                        }`}
                      >
                        DOB*
                      </label>
                      {checking && contact?.dob === '' && currentAge === '' && (
                        <div className="absolute right-10 bottom-2 flex items-center">
                          <span className="text-[12px] gotham text-[#FF6900]">
                            {' '}
                            This Field is required
                          </span>
                          <img
                            className="w-4 ml-[12px]"
                            src={'/icons/warn-circle.svg'}
                          />
                        </div>
                      )}
                      <div className="absolute -z-10 right-3 bottom-2">
                        <img className="w-auto h-auto" src={dateIcon} />
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              {/* Emergency contact */}
              {currentAge &&
                moment(new Date()).diff(moment(currentAge[0]), 'years') <
                  18 && (
                  <div className="mt-[50px]">
                    <h2 className="text-primary text-left font-bold text-[25px] uppercase leading-[90%] border-b border-b-brand-clime pb-2 font-bicyclette">
                      parent/guardian & Emergency Contact
                    </h2>
                    <div className="grid md:grid-cols-1 grid-cols-4  mt-[30px] text-white ">
                      <InputBase
                        isShowRequired={
                          checking && contact?.emergencyContactName === ''
                        }
                        errorText="Required field"
                        onchange={handleChangeInput}
                        positionMessage="bottom"
                        nameInput="emergencyContactName"
                        holder="Emergency contact name*"
                        style="lg:w-full"
                        value={contact?.emergencyContactName}
                        isRequired={true}
                      />
                      <InputBase
                        isShowRequired={
                          checking && contact?.emergencyContactRelation === ''
                        }
                        errorText="Required field"
                        onchange={handleChangeInput}
                        positionMessage="bottom"
                        nameInput="emergencyContactRelation"
                        holder="Relation to you*"
                        style="lg:w-full"
                        value={contact?.emergencyContactRelation}
                        isRequired={true}
                      />
                      <label
                        htmlFor="emergencyContactNumber"
                        className={`relative flex justify-end py-[7px] md:h-[51px] flex-col border-b  ${
                          contact.emergencyContactNumber &&
                          contact.emergencyContactNumber !== ''
                            ? 'border-white'
                            : 'border-textSecondary'
                        }`}
                      >
                        <span
                          htmlFor="emergencyContactNumber"
                          className={`absolute block bg-black text-[12px] font-medium transition-all duration-300 sm:text-base sm:font-normal ${
                            contact.emergencyContactNumber !== ''
                              ? 'top-0 sm:-top-1 left-0 text-textSecondary'
                              : 'top-[24px] sm:top-5 left-10 text-white'
                          } `}
                        >
                          Emergency phone*
                        </span>
                        <PhoneInput
                          htmlid="emergencyContactNumber"
                          country="au"
                          className="revertColor"
                          enableSearch
                          disableSearchIcon
                          placeholder=""
                          value={contact?.emergencyContactNumber || ''}
                          onChange={(phone, country) => {
                            if (phone.startsWith(country?.dialCode + '0')) {
                              phone =
                                country?.dialCode +
                                phone.slice(country?.dialCode.length + 1);
                              if (phone) {
                                setContact({
                                  ...contact,
                                  emergencyContactNumber: phone,
                                });
                                setStrapiData({
                                  ...contact,
                                  emergencyContactNumber: phone,
                                });
                              } else {
                                setContact({
                                  ...contact,
                                  emergencyContactNumber: '',
                                });
                              }
                            } else {
                              if (phone) {
                                setContact({
                                  ...contact,
                                  emergencyContactNumber: phone,
                                });
                                setStrapiData({
                                  ...contact,
                                  emergencyContactNumber: phone,
                                });
                              } else {
                                setContact({
                                  ...contact,
                                  emergencyContactNumber: '',
                                });
                              }
                            }
                          }}
                        />
                      </label>

                      <InputBase
                        isShowRequired={
                          checking && contact?.emergencyContactEmail === ''
                        }
                        errorText="Required field"
                        onchange={handleChangeInput}
                        positionMessage="bottom"
                        nameInput="emergencyContactEmail"
                        holder="Email address*"
                        style="lg:w-full"
                        value={contact?.emergencyContactEmail}
                        isRequired={true}
                      />
                    </div>
                    <div className="text-white text-start mt-[20px] flex gap-2">
                      <CheckboxActive
                        onChecked={(v) => setConsent(v)}
                        isChecked={consent}
                      />

                      <p className="text-gray font-thin font-sm ">
                        I consent that I have permission from my legal
                        parent/guardian to join Plus Fitness. I understand that
                        misleading information will result in termination of my
                        membership.
                      </p>
                    </div>
                  </div>
                )}
            </div>
          </div>

          <div
            onClick={() => {
              !currentStud && setshowSelectClubError(true);
              if (currentAge) {
                currentStud &&
                  setContact({
                    ...contact,
                    dob: moment(new Date(currentAge)).format('YYYY-MM-DD'),
                  });
                currentStud && !showSelectClubError && handleSubmit(currentAge);
              } else {
                toastError('Please select date of birth');
              }
            }}
            className="mt-[54px] w-max pb-[120px] ml-auto flex justify-end sm:pb-[150px]"
          >
            <div className="md:hidden flex">
              <ArrowButton text="NEXT" isRight={true} />
            </div>
            <div className="md:flex hidden">
              <ArrowButton text="NEXT" isRight={true} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
